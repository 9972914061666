
import { reactive, ref } from "vue";
import { Dialog } from "vant";
import { useRoute } from "vue-router";
import { storesManageService } from "@/service";
import moment from "moment";
import RejectDelayApply from "./components/RejectDelayApply.vue";
export default {
  components: {
    RejectDelayApply
  },
  setup() {
    const route = useRoute();
    const storeInfo = reactive<any>({
      data: null,
      async getStoreInfo() {
        try {
          const { data } = await storesManageService.storesDelayDetail({
            id: route.query.id,
            approval_node_lists: 1,
            opt_info: 1
          });
          storeInfo.data = data.data;
        } catch (error) {
          console.log(error, "==");
        }
      }
    });

    storeInfo.getStoreInfo();

    const rejectDelayApplyInfo = reactive<any>({
      show: false,
      onSubmit() {}
    });

    const optClick = async (type: string) => {
      try {
        switch (type) {
          case "5":
            Dialog.confirm({
              title: "",
              message: `确认通过"${storeInfo.data.title}"的申请吗`
            }).then(() => {
              storesManageService.storesDelayApproval({
                oc_id: route.query.id,
                approval_type: "1"
              });
              storeInfo.data = null;
              storeInfo.getStoreInfo();
            });
            break;

          case "6": {
            rejectDelayApplyInfo.show = true;
            const reason = await new Promise(resolve => {
              rejectDelayApplyInfo.onSubmit = (reason: string) => {
                resolve(reason);
              };
            });
            rejectDelayApplyInfo.show = false;
            storesManageService.storesDelayApproval({
              oc_id: route.query.id,
              approval_type: "2",
              reason
            });
            storeInfo.data = null;
            storeInfo.getStoreInfo();
            break;
          }

          default:
            break;
        }
      } catch (error) {
        console.log(error, "====");
      }
    };

    return {
      storeInfo,
      moment,
      optClick,
      rejectDelayApplyInfo
    };
  }
};
